.newsletter {
    padding-top: 7.5rem;
    padding-bottom: 8.5rem;
    color: var(--color-white);
    line-height: 1.5;
    font-size: 1.8rem;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    max-width: 64rem;
    margin: 0 auto;
    position: relative;
    text-align: center;

    @media (--screen-medium-large) {
        text-align: left;
        padding-top: 15rem;
        padding-bottom: 25rem;
    }

    @media (--screen-max) {
        padding-bottom: 29rem;
    }

    @media (--screen-max) {
        max-width: 96rem;
    }
}

.newsletter__title {
    color: var(--color-white);
    line-height: 1.05;
    font-size: 3rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-black);
    position: relative;
    z-index: 1;
    display: block;
    margin-bottom: 3rem;

    @media (--screen-medium-large) {
        font-size: 6.2rem;
    }

    @media (--screen-max) {
        float: left;
        width: calc(100% - 48rem);
        padding: 0 2rem 0 6.5rem;
        box-sizing: border-box;
    }
}

.newsletter__slashes {
    display: inline-block;
    fill: var(--color-secondary);
    vertical-align: top;
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(calc(-100% - 8rem));

    @media (--screen-medium-large) {
        top: -3.5rem;
        left: -3.5rem;
        transform: translateX(-50%);
        width: 30rem;
        height: 24.5rem;
    }

    @media (--screen-max) {
        width: 42rem;
        height: 32rem;
        transform: translateX(calc(-50% + 3.5rem));
    }
}

.newsletter__content {
    position: relative;
    z-index: 1;

    p {
        margin-top: 0;
        margin-bottom: 4rem;
    }

    @media (--screen-max) {
        float: left;
        width: 48rem;
    }
}
