.date-picker-day {
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.3rem;
    color: var(--color-black);
    opacity: .5;
    letter-spacing: .15rem;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;

    &:hover, &:focus {
        button, .date-picker-day__button {
            text-decoration: underline;
            color: var(--color-hover);
        }
    }

    &.-enabled, &.-active {
        opacity: 1;
    }

    &.-active button, &.-active .date-picker-day__button {
        width: 3.2rem;
        height: 3.2rem;
        padding: 0;
        line-height: 3.2rem;
        border-radius: 50%;
        background-color: var(--color-primary);
        color: var(--color-white);
        font-weight: var(--font-weight-bold);
        opacity: 1;
    }

    button, .date-picker-day__button {
        display: inline-block;
        padding: .6rem;
    }

    span {
        display: inline-block;
        padding: .6rem;
    }

}

.date-picker-day__button {
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.3rem;
    color: var(--color-black);
    letter-spacing: .15rem;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    border: 0;
    background-color: transparent;
    outline: none;
    text-decoration: none;
}
