.media {
    display: block;
    clear: both;
    margin: 6rem 0;
}

.media__title {
    display: block;
    color: var(--color-black);
    line-height: 1.05;
    font-size: 2.6rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    margin-bottom: 2rem;
}
