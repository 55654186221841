.news {
    width: 100%;

    &.-home {
        @media (--screen-large) {
            float:right;
            min-width: 50%;
            width: calc(100% - 50rem);
        }
    }
}

.news__title {
    display: inline-block;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-black);
    font-size: 4.4rem;
    line-height: 1;
    margin: 0 0 2.5rem;
    position: relative;

    @media (--screen-max) {
        margin: -.4rem 0 0 1rem;
        height: 9.5rem;
    }
}
