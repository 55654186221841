.page-footer__support {
    padding: 1rem 0 3rem;
    font-size: 1.7rem;
    font-weight: var(--font-weight-normal);
    font-family: var(--font-chivo);
    text-align: center;

    @media (--screen-medium-large) {
        padding: 7rem 0;
    }
}

.page-footer__support__list {
    margin: 0 0 2rem;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap:  wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    li {
        padding: 2rem 1.5rem;

        @media (--screen-small-large) {
            padding: 2rem 2.5rem;
        }
    }
}

.page-footer__support__title {
    margin-bottom: 0;

    &.-top {
        margin-top: 2rem;
    }
}

.page-footer__support__link {
    img {
        vertical-align: middle;
        transition: all .3s ease-in-out;
        filter: grayscale(100%);
        opacity: .6;

        @media (--screen-mini) {
            max-width: 11rem;
        }
    }

    &:hover, &:focus, &:active {
        img {
            filter: grayscale(0);
            opacity: 1;
        }
    }
}
