%form__field-reset {
    transition: all .3s ease-in-out;
    box-sizing: border-box;
    border-radius: 0;

    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS

    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 1.5rem;
    vertical-align: baseline;

    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;
    line-height: 2.6rem;

    color: var(--color-primary);
    background: none;
    border: .1rem solid var(--color-tertiary);

    // Firefox outline style
    outline: none;
}

// FF cursor pointer bug fix
input {
    cursor: pointer;
}

.form__field {
    @extend %form__field-reset;

    &.-textarea {
        resize: vertical;
    }

    &:focus {
        outline: none;
        border-color: var(--color-primary);
    }

    &::placeholder {
        color: var(--color-gray);
        font-family: var(--font-chivo);
        font-weight: var(--font-weight-normal);
        font-size: 1.6rem;
    }

    .form__entry.-invalid & {
        border-color: var(--color-red);

        // Firefox outline style & shadow
        outline: none;
        box-shadow: none;
    }

    &[type='checkbox'], &[type='radio'] {
        border: 0;
        display: none;

        + .form__label {
            position: relative;
            top: auto;
            transform: none !important;
            display: block;
            padding: 0 0 0 2.4rem;
            color: var(--color-gray);
            font-family: var(--font-pt-serif);
            font-weight: var(--font-weight-normal);
            font-size: 1.7rem;
            line-height: 1.5;

            &::before,
            &::after {
                content: '';
                display: block;
                width: 1.6rem;
                height: 1.6rem;
                position: absolute;
                top: .4rem;
                left: 0;
            }

            &::before {
                box-shadow: inset 0 0 0 .1rem var(--color-tertiary);
                background-color: var(--color-white);
            }

            &::after {
                z-index: 1;
                background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjkiIHZpZXdCb3g9IjAgMCAxMSA5IiB3aWR0aD0iMTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTQgNi42ODUyMzI3MSAzLjQ5NzU4MDk5IDMuMzE0NzY3MjkgNS41MDI0MTkwMS02IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMgLTMpIi8+PC9zdmc+');
                background-repeat: no-repeat;
                background-position: center center;
                opacity: 0;
                transition: all .2s ease;
                transform: scale(0);
            }
        }

        &:checked + .form__label::before {
            box-shadow: inset 0 0 0 .1rem var(--color-secondary);
            background-color: var(--color-secondary);
        }

        &:checked + .form__label::after {
            opacity: 1;
            transform: scale(1);
        }

        &:disabled + .form__label::before {
            box-shadow: inset 0 0 0 .1rem var(--color-disabled);
            background-color: var(--color-disabled);
        }
    }

    &[type='radio'] + .form__label::before {
        border-radius: 50%;
    }

    &[type='radio'] + .form__label::after {
        background: var(--color-white);
        width: .9rem;
        height: .9rem;
        border-radius: 50%;
        top: .7rem;
        left: .4rem;
    }
}

.form__select {
    border: .1rem solid var(--color-tertiary);
    padding-right: 3.6rem;
    cursor: pointer;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;
    line-height: 2.6rem;

    color: var(--color-primary);

    &:invalid {
        color: var(--color-gray);
    }

    &::-ms-expand {
        display: none;
    }
}

.form__select__container {
    position: relative;
    background-color: var(--color-white);

    &::after {
        content: '\e900';
        font-family: 'icons';
        display: inline-block;
        font-size: 1rem;

        color: var(--color-primary);

        position: absolute;
        top: calc(50% - .7rem);
        right: 1.8rem;
        transform: rotate(90deg);
    }
}

.form__option {
    position: relative;
    display: block;
    margin: 1rem 0 0 0;

    &:first-of-type {
        margin-top: 0;
    }
}

.form__link {
    color: var(--color-primary);
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.7rem;
    line-height: 1.5;
    text-decoration: none;
    display: block;
    margin-top: 1rem;

    @media (--screen-large) {
        display: inline-block;
        margin: 0;
    }

    &:hover, &:focus, &:active {
        color: var(--color-primary-20);
        text-decoration: none;
    }
}
