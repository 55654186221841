/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-white                   : #FFFFFF;
    --color-black                   : #000000;
    --color-dark-gray               : #2D2D2D;
    --color-gray                    : #3F4144;
    --color-red                     : #ff0000;
    --color-disabled                : #d7d7d7;

    --font-chivo                    : 'Chivo', sans-serif;
    --font-pt-serif                 : 'PT Serif', serif;
    --font-ampersand                : 'Roboto', serif;

    --font-weight-thin              : 100;
    --font-weight-extra-light       : 200;
    --font-weight-light             : 300;
    --font-weight-normal            : 400;
    --font-weight-medium            : 500;
    --font-weight-semi-bold         : 600;
    --font-weight-bold              : 700;
    --font-weight-extra-bold        : 800;
    --font-weight-black             : 900;

    --max-width                     : 146rem;
}

@custom-media --screen-end          (width >= 1460px);
@custom-media --screen-max          (width >= 1240px);
@custom-media --screen-filter       (width >= 1024px);
@custom-media --screen-large        (width >= 920px);

@custom-media --screen-medium-large (width >= 768px);
@custom-media --screen-medium       (width >= 768px) and (width < 850px);

@custom-media --screen-small-large  (width >= 420px);
@custom-media --screen-small-medium (width >= 420px) and (width < 850px);
@custom-media --screen-small        (width >= 420px) and (width < 768px);

@custom-media --screen-mini-max     (width < 1460px);
@custom-media --screen-mini-large   (width < 1240px);
@custom-media --screen-mini-filter  (width < 1024px);
@custom-media --screen-mini-medium  (width < 920px);
@custom-media --screen-mini-small   (width < 768px);
@custom-media --screen-availability (width < 520px);
@custom-media --screen-mini         (width < 420px);

/* Input placeholder mixin */
@mixin input-placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    // for Firefox 19 and below
    &:-moz-placeholder {
        @content;
    }

    // for Firefox 20 and above
    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &.placeholder {
        @content;
    }
}
