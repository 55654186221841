.alert {
    background-color: #FFE9BE;
    color: #441A07;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.8rem;
    line-height: 1.5;
    padding: 2.3rem 1.5rem 2.3rem 7rem;
    background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQxIiB2aWV3Qm94PSIwIDAgMzkgNDEiIHdpZHRoPSIzOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxyZWN0IGZpbGw9IiNlZTkzMzUiIGhlaWdodD0iNDAiIHJ4PSIxOS41IiB3aWR0aD0iMzkiIHk9Ii41Ii8+PHRleHQgZmlsbD0iI2ZmZiIgZm9udC1mYW1pbHk9IkNoaXZvLUJvbGQsIENoaXZvIiBmb250LXNpemU9IjI5IiBmb250LXdlaWdodD0iYm9sZCI+PHRzcGFuIHg9IjE1IiB5PSIzMyI+ITwvdHNwYW4+PC90ZXh0PjwvZz48L3N2Zz4=');
    background-repeat: no-repeat;
    background-position: 1.5rem 1.5rem;
    margin: 0 0 3rem;

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }
}
