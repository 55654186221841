figure {
    margin: 0 0 4rem;
    position: relative;

    &.large {
        img {
            width: 100%;
        }
    }

    &.left:not(.large) {
        float: left;
        margin-right: 3rem;
        clear: left;
    }

    &.right:not(.large) {
        float: right;
        margin-left: 3rem;
    }

    &.center:not(.large) {
        img {
            display: block;
            margin: 0 auto;
        }
    }

    figcaption {
        color: var(--color-gray);
        font-family: var(--font-pt-serif);
        font-weight: var(--font-weight-normal);
        font-style: italic;
        font-size: 1.6rem;
        line-height: 1.5;
        padding: 0;
        position: relative;
    }

    a {
        text-decoration: none;
        color: var(--color-gray);
        font-family: var(--font-pt-serif);
        font-weight: var(--font-weight-normal);
    }

    &.figure__copy {
        position: relative;

        figcaption {
            position: absolute;
            bottom: -3rem;
            right: 0;
            color: var(--color-gray);
            padding: .5rem 1rem;
        }
    }
}
