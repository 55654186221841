.card-item {
    display: block;
    text-decoration: none;
    width: 100%;
    flex-basis: 100%;
    margin: 0 0 2rem;

    @media (--screen-medium-large) {
        margin: 0 0 2rem 2rem;
        flex-basis: calc(50% - 1rem);

        &:nth-child(2n + 1) {
            margin-left: 0;
        }
    }

    @media (--screen-filter) {
        flex-basis: calc(33% - 1rem);

        &:nth-child(2n + 1) {
            margin-left: 2rem;
        }

        &:nth-child(3n + 1) {
            margin-left: 0;
        }
    }

    .card-grid.-small & {
        flex-basis: 100%;
        margin: 0 0 2rem;

        @media (--screen-filter) {
            margin: 0 0 2rem 2rem;
            flex-basis: calc(50% - 1rem);

            &:nth-child(3n + 1) {
                margin-left: 2rem;
            }

            &:nth-child(2n + 1) {
                margin-left: 0;
            }
        }
    }

    &:hover, &:focus, &:active {
        .card-item__img::before {
            opacity: .4;
        }

        .card-item__img img {
            transform: scale(1.05);
        }

        .card-item__title {
            color: var(--color-primary);
        }
    }
}

.card-item__img {
    display: block;
    position: relative;
    font-size: 0;
    margin-bottom: 1rem;
    overflow: hidden;

    .card-grid.-small & {
        margin-bottom: 1.5rem;
    }

    img {
        width: 100%;
        transition: all .3s ease-in-out;
        transform-origin: center;
    }

    &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all .3s ease-in-out;
        z-index: 0;
    }

    &::after {
        background-image: linear-gradient(180deg, color-mod(var(--color-black) a(30%)) 0%, color-mod(var(--color-black) a(10%)) 21%, color-mod(var(--color-black) a(30%)) 100%);
    }

    &::before {
        background-color: var(--color-primary);
        opacity: 0;
        z-index: 1;
    }
}

.card-item__date {
    background-color: var(--color-secondary);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.5rem;
    color: var(--color-white);
    display: inline-block;
    text-transform: uppercase;
    padding: 1rem;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: all .3s ease-in-out;
    z-index: 2;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -.6rem;
        display: inline-block;
        width: 1.2rem;
        height: 100%;
        transform: skew(15deg);
        background-color: var(--color-secondary);
        z-index: 0;
    }
}

.card-item__title {
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-black);
    font-size: 2rem;
    color: var(--color-black);
    display: block;
    transition: color .3s ease-in-out;
    padding-bottom: .2rem;

    .card-grid.-news & {
        font-weight: var(--font-weight-bold);
    }

    .ampersand {
        font-family: var(--font-ampersand);
        font-weight: var(--font-weight-black);
        font-style: normal;

        .card-grid.-news & {
            font-weight: var(--font-weight-bold);
        }
    }
}

.card-item__meta {
    display: block;
    padding-bottom: 1rem;
    color: var(--color-gray);
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.7rem;
    line-height: 1.3;
    font-style: normal;

    &.-top {
        position: relative;
        top: -.7rem;
    }
}

.card-item__status {
    color: var(--color-white);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.5rem;
    padding: 0 4.5rem 0 1.5rem;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    height: 4rem;
    line-height: 4rem;
    transition: all .3s ease-in-out;

    em {
        display: block;
        color: var(--color-white);
        font-size: .8rem;
        position: absolute;
        top: 0;
        right: -1rem;
        width: 4rem;
        height: 4rem;
        z-index: 2;
        padding-right: .5rem;
        text-align: center;
        vertical-align: middle;
        line-height: 4rem;
        transition: all .3s ease-in-out;
    }

    &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -1rem;
        background-color: var(--color-primary);
        width: 4rem;
        height: 4rem;
        transform: skew(15deg);
        transition: all .3s ease-in-out;
    }

    &::before {
        background-color: var(--color-white);
        color: var(--color-primary);
        z-index: 1;
        transform: skew(15deg) scaleX(0) ;
        transform-origin: left center;
    }

    .card-item:hover & {
        padding-right: 5rem;

        &::before {
            transform: skew(15deg) scaleX(1);
        }

        em {
            color: var(--color-primary);
        }
    }

    &.-gray {
        padding: 0 1.5rem 0 2.5rem !important;

        &:after, em {
            display: none;
        }

        &:before {
            background: var(--color-black);
            opacity: .7;
            transform: skew(15deg) !important;
            width: 100%;
            z-index: -1;
        }
    }
}
