.events-soon {
    display: block;
    width: 100%;
    margin-bottom: 5rem;

    @media (--screen-large) {
        float: left;
        width: calc(50% - 2rem);
        margin-bottom: 0;
        max-width: 44rem;
    }
}

.events-soon__title {
    display: inline-block;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-black);
    font-size: 4.4rem;
    line-height: 1;
    margin: -.4rem 0 0 1rem;
    position: relative;
    height: 9.5rem;

    em {
        display: block;
        padding-top: .4rem;
        font-size: 2.4rem;
        font-weight: var(--font-weight-normal);
        color: var(--color-primary);
        font-style: normal;
        line-height: 1;
    }
}

.events-soon__slashes {
    display: inline-block;
    fill: var(--color-primary);
    transform: scaleX(-1);
    vertical-align: top;
}

.events-soon .events-list {
    background-color: var(--color-quaternary);
}
