
.events-detail__price {
    display: block;
    line-height: 2.5;
    font-size: 2.2rem;
    color: var(--color-black);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    padding-left: 5rem;
    position: relative;

    em {
        font-family: var(--font-pt-serif);
        font-weight: var(--font-weight-normal);
        font-style: italic;
        font-size: 1.6rem;
        color: var(--color-gray);
        padding-left: .5rem;
    }

    &::before {
        content: '\e909';
        font-family: 'icons';
        font-size: 1.6rem;

        color: var(--color-primary);

        position: absolute;
        top: .8rem;
        left: .7rem;
    }
}

.events-detail__price__info {
    display: inline-block;
    background: var(--color-white);
    padding: 1.5rem;
    border-bottom: .2rem solid var(--color-primary-60);
    line-height: 1.5;
    font-size: 1.5rem;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    color: var(--color-gray);
    position: relative;
    margin-top: 1.5rem;

    @media (--screen-filter) {
        margin-left: 5rem;
    }

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }

    &::after {
        bottom: 100%;
        left: 6.5rem;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: var(--color-white);
        border-width: 1.5rem;


        @media (--screen-filter) {
            left: 1.5rem;
        }
    }
}

.events-detail__audience {
    color: var(--color-gray);
    font-size: 1.7rem;
    line-height: 1.5;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    padding-left: 5rem;
    position: relative;
    margin-bottom: 3rem;

    &::before {
        content: '\e90a';
        font-family: 'icons';
        font-size: 1.8rem;

        color: var(--color-primary);

        position: absolute;
        top: -.1rem;
        left: .7rem;
    }
}

.events-detail__date {
    display: block;
    color: var(--color-black);
    font-size: 2.2rem;
    line-height: 1.5;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    padding-left: 5rem;
    position: relative;
    margin-bottom: 1rem;

    &::before {
        content: '\e902';
        font-family: 'icons';
        font-size: 2rem;

        color: var(--color-primary);

        position: absolute;
        top: -.1rem;
        left: .7rem;
    }
}

.events-detail__title {
    display: block;
    color: var(--color-black);
    font-size: 2.2rem;
    line-height: 1.5;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    padding-left: 5rem;
    position: relative;
    margin-bottom: 1rem;

    &::before {
        content: '\e90b';
        font-family: 'icons';
        font-size: 2rem;

        color: var(--color-primary);

        position: absolute;
        top: -.1rem;
        left: .7rem;
    }

    &.-icon-language {
        &::before {
            content: '';
            font-family: inherit;
            mask-image: url('../Images/icon-language.svg');
            mask-size: cover;
            background-color: currentcolor;
            width: 2.2rem;
            height: 1.9rem;
            top: .7rem;
        }
    }
}

.events-detail__activities {
    margin: 0 0 5rem 5rem;
    color: var(--color-gray);
    font-size: 1.7rem;
    line-height: 1.5;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    padding: 0;
    list-style: none;

    li {
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.events-detail__locations {
    margin: 0 0 5rem 5rem;
    color: var(--color-gray);
    font-size: 1.7rem;
    line-height: 1.5;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    padding: 0;
    list-style: none;

    li {
        margin-bottom: 3rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.events-detail__locations__title {
    display: flex;
    align-items: center;

    strong {
        margin-right: auto;
    }

    .icon {
        width: 3rem;
        height: 3rem;
        font-size: 2.2rem;
        text-align: center;
        overflow: hidden;
    }
}

.events-detail__facebook {
    background-color: #3C5A98;
    font-size: 1.5rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    color: var(--color-white);
    text-decoration: none;
    display: inline-block;
    line-height: 5rem;
    padding: 0 1.4rem 0 6.4rem;
    position: relative;
    height: 5rem;
    margin: 0 0 5rem 5rem;

    &::before {
        content: '\e90c';
        font-family: 'icons';
        display: inline-block;
        line-height: 5rem;
        background-color: #264482;
        text-align: center;
        font-size: 2rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 4.4rem;
        height: 5rem;
    }

    &:hover, &:focus, &:active {
        background-color: color-mod(#3C5A98 tint(20%));

        &:before {
            background-color: color-mod(#264482 tint(20%));
        }
    }
}

.events-detail__list {
    margin: 0 0 5rem;
    padding: 0;
    list-style: none;

    a {
        display: flex;
        align-items: center;

        img {
            border: .1rem solid var(--color-tertiary);
            margin-right: 1.3rem;
        }
    }
}

.events-detail__img {
    position: relative;
    margin: 0 -1.5rem 1.5rem -1.5rem;

    @media (--screen-filter) {
        margin: 0;
    }
}

.events-detail__extrainfo {
    @media (--screen-filter) {
        display: none;
    }

    &.-max {
        display: none;

        @media (--screen-filter) {
            display: block;
        }
    }
}

.events-detail__language {
    color: var(--color-gray);
    font-size: 1.7rem;
    line-height: 1.5;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-bold);
    display: flex;
    flex-wrap: wrap;
    gap: .5rem 1rem;
    align-items: center;
    text-decoration: none;
    margin: 0 0 5rem 5rem;

    span:not([class]) {
        font-size: 1.2rem;
        align-self: end;
        transform: translateY(-1px);
    }

    svg {
        inline-size: 8rem;

        * {
            fill: var(--color-primary);
            stroke: var(--color-primary);
        }
    }

    &.-level-1 {
        path:nth-child(n+2) {
            fill: none;
        }
    }

    &.-level-2 {
        path:nth-child(n+3) {
            fill: none;
        }
    }

    &.-level-3 {
        path:nth-child(n+4) {
            fill: none;
        }
    }

    &.-level-4 {
        // do nothing
    }
}

.events-detail__language-label {
    flex: 1;
    flex-basis: 100%;
}

.events-detail__language-locale {

}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 4rem 0;

    .article__info & {
        margin: 0;
    }
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
