.page-header__nav {
    display: flex;
    flex-direction: row;
    align-items: center;

    background: var(--color-white);
    position: relative;
    padding: .5rem 0 .5rem 1.5rem;

    margin: 0 -1.5rem;

    border-bottom: .1rem solid var(--color-tertiary);

    @media (--screen-medium-large) {
        padding: 1rem;
        margin: 0;
        border-bottom: 0;
    }

    @media (--screen-end) {
        padding: 1rem 3rem 1rem 0;
    }

    &::after {
        content: '';
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 58rem .4rem 0;
        border-color: transparent var(--color-quaternary) transparent transparent;
        z-index: -4;

        @media (--screen-medium-large) {
            display: block;
            bottom: -.4rem;
            right: 16.5rem;
        }

        @media (--screen-end) {
            right: 15rem;
        }
    }

    &::before {
        content: '';
        display: block;
        background: var(--color-white);
        background: linear-gradient(70deg, transparent 0%, transparent 3rem, var(--color-secondary) 3rem, var(--color-secondary) 100%);

        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 8rem;
        z-index: 1;

        @media (--screen-medium-large) {
            display: none;
        }

        .-navigation-active & {
            @media (--screen-mini-max) {
                display: none;
            }
        }
    }

    .-navigation-active & {
        @media (--screen-mini-max) {
            height: 100vh;
            align-items: flex-start;
            display: block;
            overflow-y: scroll;
            box-sizing: border-box;
        }
    }
}
