.search-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    transform: translateY(-100%);
    transition: all .3s ease-in-out;
    opacity: 0;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: var(--color-primary);
        opacity: .9;
    }

    .-search-active & {
        transform: translateY(0);
        opacity: 1;
    }
}

.search-overlay__form {
    max-width: 66rem;
    width: 100%;
    margin: 0 auto;
    border-bottom: .2rem solid var(--color-white);
    padding: 3rem 0;
    position: absolute;
    top: 30vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    input {
        box-sizing: border-box;
        height: 6rem;
        line-height: 6rem;
        width: 100%;
        background-color: transparent;
        border: 0;
        outline: none;
        padding: 0 25rem 0 6rem;
        color: var(--color-white);
        font-family: var(--font-chivo);
        font-weight: var(--font-weight-black);
        font-size: 4.4rem;
        position: relative;
        z-index: 2;

        @include input-placeholder {
            color: var(--color-white);
            font-family: var(--font-chivo);
            font-weight: var(--font-weight-black);
            font-size: 4.4rem;
        }
    }

    svg {
        fill: var(--color-white);
        stroke: var(--color-white);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    button {
        color: var(--color-white);
        font-family: var(--font-pt-serif);
        font-style: italic;
        font-size: 1.6rem;
        background-color: transparent;
        border: 0;
        outline: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.search-overlay__form__close {
    position: absolute;
    z-index: 1;
    top: 2.5rem;
    right: 1.5rem;
    font-size: 0;
    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;

    @media (--screen-max) {
        top: 4rem;
        right: 4rem;
    }

    &::after {
        content: '\e907';
        font-family: 'icons';
        color: var(--color-white);
        font-size: 2.2rem;
    }

    &:hover, &:active, &:focus {
        &::after {
            color: var(--color-secondary);
        }
    }

}
