.search__title {
    display: block;
    text-align: center;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    color: var(--color-black);
    font-size: 2.2rem;
    line-height: 1.5;
}

.search__form {
    width: 100%;
    max-width: 72rem;
    margin: 4rem auto;
    position: relative;
    height: 6rem;

    svg {
        position: absolute;
        top: 2rem;
        left: 1.5rem;
        stroke: var(--color-black);
        fill: var(--color-black);
    }

    input {
        border: .1rem solid var(--color-tertiary);
        display: block;
        padding: 0 12rem 0 5rem;
        width: 100%;
        box-sizing: border-box;
        height: 6rem;
        line-height: 6rem;
        color: var(--color-primary);
        font-family: var(--font-chivo);
        font-weight: var(--font-weight-normal);
        font-size: 1.8rem;

        @include input-placeholder {
            color: var(--color-dark-gray);
            font-family: var(--font-chivo);
            font-weight: var(--font-weight-normal);
            font-size: 1.8rem;
        }
    }

    .button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        height: 6rem;
        line-height: 6rem;
        padding: 0 2rem;

        &::after {
            display: none;
        }
    }
}

.search__section {
    display: block;
    padding: 5rem 0;
}

.search__section__title {
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    color: var(--color-primary);
    font-size: 2.8rem;
    display: block;
    text-align: center;
}
