.contact {
    display: flex;
    flex-direction: column;
    font-size: 1.7rem;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    line-height: 1.5;
    padding-top: 3rem;

    @media (--screen-large) {
        flex-direction: row;
    }
}

.contact__info {
    width: 100%;
    max-width: 37rem;
    margin-bottom: 3rem;

    @media (--screen-large) {
        margin-bottom: 0;
    }

    p {
        margin-top: 0;
    }
}

.contact__form {
    width: 100%;
    max-width: 60rem;

    .alert-message__close {
        display: none;
    }

    &.-group {
        max-width: 72rem;
    }
}

.contact__title {
    font-size: 2.6rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    line-height: 1;
    display: block;
    margin-bottom: 2rem;
}

