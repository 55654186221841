.page-footer__nav {
    @media (--screen-medium) {
        margin-left: 10rem;
    }
    @media (--screen-large) {
        margin-left: 20rem;
    }
}

.page-footer__nav__list, .page-footer__nav__children {
    margin: 0;
    padding: 0;
    list-style: none;
}

.page-footer__nav__list {
    display: flex;
    flex-direction: column;

    @media (--screen-small-large) {
        flex-direction: row;
    }

    & > li {
        margin: 0 6rem 4rem 0;

        @media (--screen-large) {
            margin: 0 0 0 12rem;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

.page-footer__nav__item {
    font-size: 2.2rem;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-chivo);
    text-decoration: none;
    color: var(--color-black);
    margin-bottom: 2rem;
    display: inline-block;

    @media (--screen-medium-large) {
        margin-bottom: 4rem;
    }

    &:hover, &:focus, &:active {
        color: var(--color-primary);
    }

    .ampersand {
        font-family: var(--font-ampersand);
        font-weight: var(--font-weight-bold);
        font-style: normal;
    }
}

.page-footer__nav__child {
    font-size: 1.7rem;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    line-height: 2;
    text-decoration: none;
    color: var(--color-primary);

    &:hover, &:focus, &:active {
        text-decoration: underline;
    }
}
