.navigation-sub {
    display: none;

    @media (--screen-end) {
        margin-left: auto;
        display: inline-block;
    }

    .-navigation-active & {
        @media (--screen-mini-max) {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            padding: 3rem 1rem;
            box-sizing: border-box;

            @media (--screen-medium-large) {
                height: 100%;
                padding: 20rem 0 0;
                display: block;
                width: calc(50% - 16rem);
                float: left;
                margin-left: 16rem;
            }
        }
    }
}

.navigation-sub__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navigation-sub__item {
    display: inline-block;
    padding: 0 1.5rem;

    .-navigation-active & {
        @media (--screen-mini-max) {
            display: block;
            padding: 1rem;

            &.-search {
                position: fixed;
                top: 2rem;
                right: 8rem;

                @media (--screen-medium-large) {
                    top: 6.5rem;
                    right: 9rem;
                }

                svg {
                    fill: var(--color-white);
                    stroke: var(--color-white);
                }
            }
        }
    }

    a {
        font-family: var(--font-chivo);
        font-weight: var(--font-weight-normal);
        font-size: 1.6rem;
        color: var(--color-dark-gray);
        text-decoration: none;
        transition: color .3s ease-in-out;

        .ampersand {
            font-family: var(--font-ampersand);
            font-weight: var(--font-weight-normal);
            font-style: normal;
        }

        .-navigation-active & {
            @media (--screen-mini-max) {
                color: var(--color-white);
                font-size: 1.8rem;
            }
        }

        svg {
            fill: var(--color-black);
            stroke: var(--color-black);

            vertical-align: middle;

            transition: all .3s ease-in-out;
        }

        &:hover, &:focus, &:active {
            color: var(--color-primary);

            .-navigation-active & {
                @media (--screen-mini-max) {
                    color: var(--color-secondary);
                }
            }

            svg {
                fill: var(--color-primary);
                stroke: var(--color-primary);

                .-navigation-active & {
                    @media (--screen-mini-max) {
                        fill: var(--color-secondary);
                        stroke: var(--color-secondary);
                    }
                }
            }
        }
    }

    &.-active a {
        color: var(--color-primary);

        .-navigation-active & {
            @media (--screen-mini-max) {
                color: var(--color-secondary);
            }
        }
    }
}
