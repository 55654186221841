.events-activities {
    display: block;
    margin: 4rem auto;
    padding: 0;
    list-style: none;
    border-bottom: .1rem solid var(--color-tertiary);

    &.-past {
        margin: 2rem auto;
        border-bottom: 0;
    }
}

.events-activities__item {
    border-top: .1rem solid var(--color-tertiary);
    padding: 2rem 0;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;
    color: var(--color-black);
    line-height: 1.5;

    @media (--screen-large) {
        display: flex;
        align-items: flex-start;

        .events-activities.-past & {
            flex-direction: column;
        }
    }
}

.events-activities__item__date {
    min-width: 20rem;
    margin: 0 0 1rem 0;
    font-family: var(--font-chivo);
    font-size: 1.6rem;
    font-weight: var(--font-weight-normal);

    strong {
        display: block;
        font-size: 1.8rem;
        font-weight: var(--font-weight-black);
        text-transform: uppercase;
        margin-bottom: 1rem;

        @media (--screen-large) {
            font-size: 2.2rem;
        }
    }

    @media (--screen-large) {
        margin: 0 2rem 0 0;
    }
}

.events-activities__item__location {
    margin: 0 0 1rem 0;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;
    overflow: hidden;

    @media (--screen-large) {
        margin: 0 2rem 0 0;
    }

    &::before {
        content: '\e90b';
        font-family: 'icons';
        font-size: 1.4rem;
        margin-right: 1rem;
        color: var(--color-tertiary);
        float: left;
    }

    span {
        float: left;
    }

    .events-activities.-past & {
        margin: 1rem 0 0;

        &::before {
            display: none;
        }
    }
}

.events-activities__item__button {
    margin: 2rem 0 0;
    clear: both;

    .button {
        margin: 0;
    }

    @media (--screen-large) {
        margin: 0 0 0 auto;
        clear: none;
    }
}

.events-activities-past__title {
    color: var(--color-primary);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 2.2rem;
    margin: 0;

    @media (--screen-max) {
        font-size: 2.8rem;
    }
}
