.page-footer__copy {
    padding: 1rem 0 3rem;
    text-align: center;
    font-size: 1.5rem;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    color: var(--color-gray);
    line-height: 1.25;

    a {
        color: var(--color-primary);
        text-decoration: none;

        &:hover, &:focus, &:active {
            text-decoration: underline;
        }
    }

    position: relative;

    &::after {
        content: '';
        display: block;
        background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ1MzkiIHZpZXdCb3g9IjAgMCAyOTc0IDQ1MzkiIHdpZHRoPSIyOTc0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDAgMTI5Ni41NSA0NTM5aDE2NzcuNDVsLTEyOTYuNjEtNDUzOXoiIGZpbGw9IiM1NmJkYmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
        background-size: 100%;
        background-position: bottom right;
        background-repeat: no-repeat;

        position: absolute;
        bottom: 0;
        right: calc(100% - 5rem);
        height: 100vh;
        width: 100vw;
        z-index: -1;

        @media (--screen-large) {
            right: calc(100% - 14rem);
        }

        @media (--screen-end) {
            right: 100%;
            transform: translateX(20rem);
        }

        .-theme-blue & {
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ1MzkiIHZpZXdCb3g9IjAgMCAyOTc0IDQ1MzkiIHdpZHRoPSIyOTc0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDAgMTI5Ni41NSA0NTM5aDE2NzcuNDVsLTEyOTYuNjEtNDUzOXoiIGZpbGw9IiMwMEEzREEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
        }

        .-theme-pink & {
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ1MzkiIHZpZXdCb3g9IjAgMCAyOTc0IDQ1MzkiIHdpZHRoPSIyOTc0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDAgMTI5Ni41NSA0NTM5aDE2NzcuNDVsLTEyOTYuNjEtNDUzOXoiIGZpbGw9IiNFMzgxOUUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
        }

        .-theme-orange & {
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ1MzkiIHZpZXdCb3g9IjAgMCAyOTc0IDQ1MzkiIHdpZHRoPSIyOTc0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDAgMTI5Ni41NSA0NTM5aDE2NzcuNDVsLTEyOTYuNjEtNDUzOXoiIGZpbGw9IiNGRDkxNTciIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
        }

        .-theme-green & {
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ1MzkiIHZpZXdCb3g9IjAgMCAyOTc0IDQ1MzkiIHdpZHRoPSIyOTc0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDAgMTI5Ni41NSA0NTM5aDE2NzcuNDVsLTEyOTYuNjEtNDUzOXoiIGZpbGw9IiM5ODlBMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
        }
    }
}

.page-footer__copy__links {
    display: block;
    margin: 0;
    padding: 1rem 0;
    list-style: none;

    @media (--screen-medium-large) {
        display: block;
        padding: 2rem 1.5rem;
    }

    @media (--screen-max) {
        display: inline-block;
        padding: 0 1.5rem;
    }

    li {
        margin: 0;
        padding: 1rem 0;
        display: block;

        @media (--screen-medium-large) {
            padding: 0 1.5rem;
            display: inline-block;
        }
    }
}
