.partners {
    padding: 5rem 0 0 0;
}

.partners__title {
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    font-size: 2.2rem;
    color: var(--color-black);
    display: block;
    text-align: center;
    margin: 0 0 1rem;

    .ampersand {
        font-family: var(--font-ampersand);
        font-weight: var(--font-weight-bold);
        font-style: normal;
    }
}

.partners__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 2.5rem;
    }
}

.partners__item {
    img {
        vertical-align: middle;
    }
}
