.pagination-list {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin: 7rem 0 2rem 0;
    padding: 0;
}

.pagination-list__page {
    width: 5.2rem;
    height: 5.2rem;
    display: inline-block;
    color: var(--color-black);
    font-size: 1.7rem;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    text-decoration: none;
    text-align: center;
    line-height: 5.2rem;
    margin: .1rem;

    em {
        font-size: 1.1rem;
    }

    a {
        color: var(--color-black);
        font-size: 1.7rem;
        font-family: var(--font-pt-serif);
        font-weight: var(--font-weight-normal);
        text-decoration: none;
        width: 5.2rem;
        height: 5.2rem;
        display: inline-block;
    }

    &.-active, &.-active a, a:hover, a:focus, a:active {
        color: var(--color-secondary);
    }

    &.-button {
        border: .1rem solid var(--color-quaternary);

        a:hover, a:focus, a:active {
            background-color: var(--color-quaternary);
            color: var(--color-black);
        }
    }
}
