main {
    flex: 1 0 auto; // used for sticky footer using flexbox
    margin-bottom: 3rem;

    @media (--screen-medium-large) {
        margin-bottom: 12rem;
    }
}

[data-hidden], [data-component-config] {
    display: none;
}
