.breadcrumbs {
    margin: 0 0 2rem;
    padding: 0;
    list-style: none;
}

.breadcrumbs__item {
    font-size: 1.7rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    color: var(--color-primary);

    a {
        text-decoration: none;
        color: var(--color-primary);
        position: relative;
        transition: all .3s ease-in-out;

        &::before {
            display: inline-block;
            content: '\e901';
            font-family: 'icons';
            font-size: 1rem;
            border: .1rem solid var(--color-tertiary);
            width: 3.8rem;
            height: 3.8rem;
            border-radius: 50%;
            line-height: 1;
            box-sizing: border-box;
            padding: 1.4rem 0;
            text-align: center;
            margin: 0 .8rem 0 0;
            transition: all .3s ease-in-out;
            position: relative;
            top: -.2rem;
        }

        &:hover, &:focus, &:active {
            color: var(--color-primary-20);

            &::before {
                background-color: var(--color-tertiary);
            }
        }
    }
}
