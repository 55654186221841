.rooms {
    display: block;
    clear: both;

    + .rooms {
        margin-top: 9rem;
    }
}

.article__content + .rooms {
    margin-top: 9rem;
}

.rooms__title {
    color: var(--color-primary);
    font-size: 2rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    display: block;
    position: relative;
    line-height: 1.5;

    @media (--screen-medium-large) {
        overflow: hidden;
    }

    @media (--screen-large) {
        font-size: 2.8rem;
    }

    &::after {
        display: block;
        content: '';
        width: 100%;
        height: .1rem;
        background-color: var(--color-tertiary);
        position: relative;
        top: 1rem;

        @media (--screen-medium-large) {
            position: absolute;
            top: 50%;
            margin-left: 2rem;
            display: inline-block;
        }
    }
}

.rooms__availability {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-family: var(--font-chivo);
    font-size: 1.7rem;
    column-gap: 1.5rem;

    @media (--screen-availability) {
        position: relative;
    }

    tr {
        flex: 1 1 calc(100%/3);
    }

    th {
        display: block;
        box-sizing: border-box;
        height: 6rem;
        width: 100%;
        text-align: right;
        font-weight: var(--font-weight-normal);
        color: var(--color-primary);
        line-height: 1.5;
        border: .1rem solid var(--color-white);

        @media (--screen-availability) {
            font-size: 1.5rem;
            padding-top: .4rem;
        }

        @media (--screen-availability) {
            text-align: left;
            border-color: transparent;
        }

        @media (--screen-filter) {
            border: 0;
            display: table-cell;
            width: calc(100%/8);
            text-align: center;
        }

        strong {
            color: var(--color-black);
            display: block;
        }

    }

    td {
        width: 100%;
        height: 6rem;
        vertical-align: middle;
        display: block;
        text-align: center;
        border: .1rem solid var(--color-white);
        box-sizing: border-box;

        &.-label {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 0;

            @media (--screen-availability) {
                font-size: 1.3rem;
            }

            @media (--screen-filter) {
                display: table-cell;
                padding-right: 1rem;
            }

            @media (--screen-max) {
                padding-right: 2.5rem;
            }
        }

        &.-available {
            background: var(--color-secondary);
        }
        &.-occupied {
            opacity: .5;
            background: repeating-linear-gradient(-55deg, var(--color-secondary), var(--color-secondary) .5rem, var(--color-tertiary) .5rem, var(--color-tertiary) 1rem);
        }

        @media (--screen-filter) {
            border: 0;
            width: calc(100%/8);
            display: table-cell;
            text-align: right;
        }
    }

    thead {
        @media (--screen-availability) {
            position: absolute;
            top: .2rem;
            left: .7rem;
            z-index: 1;
        }
    }

    thead, tbody {
        display: flex;
    }

    tbody {
        flex: 1 1 75%;
    }

    @media (--screen-filter) {
        display: table;

        thead {
            display: table-header-group;
        }

        tbody {
            display: table-row-group;
        }
    }
}

.rooms__availability__caption {
    display: block;
    flex: 0 0 100%;

    ul {
        margin: 0;
        list-style: none;
        float: right;
        border: .1px solid var(--color-tertiary);
        padding: 1.5rem;

        li {
            display: inline-block;
            position: relative;
            padding-left: 2.6rem;
            margin-right: 2rem;
            color: var(--color-primary);
            font-size: 1.5rem;
            font-family: var(--font-chivo);
            font-weight: var(--font-weight-normal);

            &::before {
                content: '';
                width: 2rem;
                height: 2rem;
                background: var(--color-secondary);
                left: 0;
                top: .1rem;
            }

            &:last-child {
                margin-right: 0;

                &::before {
                    opacity: .5;
                    background: repeating-linear-gradient(-55deg, var(--color-secondary), var(--color-secondary) .5rem, var(--color-tertiary) .5rem, var(--color-tertiary) 1rem);
                }
            }
        }
    }

    @media (--screen-filter) {
        display: table-caption;
        margin-bottom: 2rem;
    }

    @media (--screen-availability) {
        margin-top: 2rem;
        order: 5;
    }
}

.rooms__availability__week {
    display: block;
    font-size: 2rem;
    font-family: var(--font-chivo);
    text-align: center;
    font-weight: var(--font-weight-bold);
    line-height: 1.5;

    @media (--screen-large) {
        font-size: 2.8rem;
    }

    a {
        display: inline-block;
        margin: 0 1rem;
        text-decoration: none;
        color: var(--color-primary);
        position: relative;
        transition: all .3s ease-in-out;

        &.-disabled {
            opacity: .2;
            pointer-events: none;
        }

        &::before {
            display: inline-block;
            content: '\e901';
            font-family: 'icons';
            font-size: 1rem;
            border: .1rem solid var(--color-tertiary);
            width: 3.8rem;
            height: 3.8rem;
            border-radius: 50%;
            line-height: 1;
            box-sizing: border-box;
            padding: 1.4rem 0;
            text-align: center;
            margin: 0 .8rem 0 0;
            transition: all .3s ease-in-out;
            position: relative;
            top: -.4rem;
        }

        &:hover, &:focus, &:active {
            color: var(--color-primary-20);

            &::before {
                background-color: var(--color-tertiary);
            }
        }

        &:last-child {
            &::before {
                transform: rotate(180deg);
            }
        }
    }
}
