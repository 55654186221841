.form__message {
    position: relative;
    box-sizing: border-box;
    display: block;

    margin-top: 0;

    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.4rem;
    line-height: 1;
    color: var(--color-red);
    opacity: 0;
    padding: 1rem 1rem 1rem 0;
    transform: scaleY(0);
    overflow: hidden;

    transition: all .2s ease;

    .-invalid & {
        opacity: 1;
        transform: scaleY(1);
    }

    &.-hide {
        opacity: 0;
        transform: scaleY(0);
        position: absolute;
        z-index: -1;
    }
}

.form__error {
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.7rem;
    line-height: 1.25;
    color: var(--color-red);
    margin-bottom: 3rem;
}

.form__info {
    position: relative;
    box-sizing: border-box;
    display: block;

    margin-top: 0;

    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.4rem;
    line-height: 1;
    color: var(--color-gray);
    padding: 1rem 1rem 1rem 0;
    overflow: hidden;
}
