.date-picker {
    margin: 0;
    padding: 0;

    position: relative;
    align-self: stretch;
    display: flex;
    align-items: stretch;

    flex-basis: calc(50% - .1rem);
    border-top: .2rem solid var(--color-white) !important;

    @media (--screen-max) {
        flex-basis: auto;
        border-top: 0 !important;
        border-left: .2rem solid var(--color-white) !important;
    }

    &.-open {
        z-index: 2;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: .3rem;
            background-color: var(--color-quaternary);
            position: absolute;
        }
    }
}

.date-picker__label {
    padding: 2rem 4rem 2rem 1.5rem;
    width: 100%;
    box-sizing: border-box;
    height: 100%;

    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;

    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 0;
    color: var(--color-white);
    line-height: 1;
    text-align: left;

    position: relative;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;

    span {
        font-size: 1.6rem;
        text-transform: capitalize;

        @media (--screen-medium-large) {
            text-transform: none;
        }
    }

    @media (--screen-medium-large) {
        font-size: 1.6rem;
    }

    .date-picker.-open & {
        background-color: var(--color-quaternary);
        color: var(--color-primary)
    }

    &::before {
        content: '\e902';
        font-family: 'icons';
        display: inline-block;
        font-size: 2rem;

        color: var(--color-secondary);
        margin-right: 1rem;

        position: relative;
        top: .2rem;
    }

    &::after {
        content: '\e900';
        font-family: 'icons';
        display: inline-block;
        font-size: 1rem;

        color: var(--color-white);

        position: absolute;
        top: calc(50% - .2rem);
        right: 1.5rem;
        transform: rotate(90deg);
        transition: all .3s ease-in-out;

        .date-picker.-open & {
            color: var(--color-primary);
            transform: rotate(-90deg);
        }
    }

    &:hover, &:focus {
        background-color: var(--color-primary-20);
    }
}

.date-picker__options {
    width: 100vw;
    max-width: 29rem;
    height: 100vh;
    max-height: 0;
    overflow: hidden;

    opacity: 0;

    position: absolute;
    left: 0;
    top: calc(100% + .2rem);

    background-color: var(--color-quaternary);

    transition: opacity .3s ease-in-out;

    @media (--screen-mini-filter) {
        min-width: 29rem;
        max-width: 100%;
    }

    .date-picker.-open & {
        z-index: 5;
        opacity: 1;
        max-height: 36.8rem;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}
