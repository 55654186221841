.page-header__logo {
    display: inline-block;
    margin-right: 1.5rem;

    img {
        width: 15rem;
        vertical-align: middle;
        display: inline-block;

        @media (--screen-medium-large) {
            width: auto;
        }

        &.-desktop {
            display: none;

            @media (--screen-large) {
                position: absolute;
                margin-top: -1.4rem;
            }
        }
    }

    .-navigation-active & {
        @media (--screen-mini-max) {
            display: none;
        }
    }
}

@media (--screen-end) {
    .page-header__logo {
        img {
            visibility: hidden;
            width: 11.5rem;

            &.-desktop {
                display: inline-block;
                visibility: visible;
            }
        }
    }
}
