.date-picker-calendar__title {
    padding: 1.5rem 0;

    table-layout: fixed;

    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;
    color: var(--color-black);
    text-align: center;
    line-height: 1.2;
}

.date-picker-calendar__header {
    display: flex;
    padding: 1rem 0 0;
}

.date-picker-calendar__header__today, .date-picker-calendar__header__close {
    margin-right: auto;
    margin-left: .9rem;
    text-transform: uppercase;
    color: var(--color-primary);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1rem;
    line-height: 1;
    letter-spacing: .125rem;
    background: none;
    outline: none;
    border: 0;
    cursor: pointer;

    &:hover, &:focus {
        text-decoration: underline;
    }
}

.date-picker-calendar__header__close {
    margin-left: auto;
    margin-right: .9rem;
}
