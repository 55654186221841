ul:not([class]),
ol:not([class]),
.list {
    display: block;
    margin: 0 0 2rem 1rem;
    padding: 0;
    list-style: none;

    font-size: 1.7rem;
    line-height: 1.6;
    color: var(--color-gray);
    font-family: var(--font-pt-serif);

    li {
        position: relative;
        padding: 0 0 0 1rem;

        &::before {
            content: '\2022';
            color: var(--color-black);
            font-weight: var(--font-weight-bold);
            display: inline-block;
            margin: 0;
            font-size: 1.5rem;
            text-align: right;
            width: 3rem;
            position: absolute;
            left: -3rem;
            top: .3rem;
        }
    }
}

ol:not([class]) {
    li {
        counter-increment: li;

        &::before {
            content: counter(li) '.';
        }
    }
}

.list-overview {
    display: block;
    border-bottom: .1rem solid var(--color-quaternary);
    width: 100%;
    max-width: 72rem;
    margin: 4rem auto;
    list-style: none;
    padding: 0;

    li {
        display: block;
        border-top: .1rem solid var(--color-quaternary);
    }
}

.list-overview__item {
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    font-size: 2rem;
    display: block;
    text-decoration: none;
    color: var(--color-black);
    padding: 2rem 0;
    line-height: 1.1;
    transition: all .3s ease-in-out;
    position: relative;

    &::after {
        content: '\e900';
        font-family: 'icons';
        font-size: 1.3rem;
        display: block;
        position: absolute;
        top: 2.2rem;
        right: 1.2rem;
        color: var(--color-black);
        transition: all .3s ease-in-out;
    }

    .ampersand {
        font-family: var(--font-ampersand);
        font-weight: var(--font-weight-bold);
        font-style: normal;
    }

    &:hover, &:focus, &:active {
        color: var(--color-primary);

        &::after {
            color: var(--color-primary);
            right: 0;
        }
    }
}

dl {
    font-size: 1.7rem;
    line-height: 2.7rem;
    color: var(--color-black);
    font-family: var(--font-pt-serif);
    margin: 0 0 3rem;
    width: 100%;
    display: block;
    overflow: hidden;

    &:nth-child(even) {
        background-color: var(--color-quaternary);
    }

    &.-school {
        background-color: transparent;
    }

    dd, dt {
        margin: 0;
        display: block;
        padding: .5rem 1.5rem .5rem 0;
        box-sizing: border-box;
        float: left;

        @media (--screen-large) {
            padding: .5rem 1rem;
            border-top: .1rem solid var(--color-tertiary);
            width: 50%;
        }

        @media (--screen-max) {
            width: auto;
            min-width: 28rem;
        }
    }

    dd {
        @media (--screen-max) {
            margin-right: 3rem;
        }
    }

    dt {
        padding-right: 1rem;
        color: var(--color-gray);
        font-size: 1.5rem;
        clear: left;

        &::after {
            content: ':';
            display: inline-block;
        }

        @media (--screen-max) {
            clear: none;
        }

        &.-clear {
            @media (--screen-large) {
                clear: left;
            }
        }
    }
}
