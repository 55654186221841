.media__files__list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.media__files__item {
    padding: 2rem 0;
    border-bottom: .1rem solid var(--color-tertiary);
    overflow: hidden;

    a {
        margin-left: auto;
        display: inline-block;
        position: relative;
        color: var(--color-primary);
        font-family: var(--font-pt-serif);
        font-weight: var(--font-weight-bold);
        font-size: 1.7rem;
        text-decoration: underline;
        transition: all .3s ease-in-out;
        line-height: 1.6;

        &:hover, &:focus, &:active {
            color: var(--color-primary-20);
            text-decoration: none;
        }
    }
}
