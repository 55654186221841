blockquote {
    display: block;
    overflow: hidden;
    margin: 4rem 0;
    padding: 2rem 0 2rem 8rem;
    position: relative;

    &::before {
        content: '\e90d';
        font-family: 'icons';
        color: var(--color-secondary);
        position: absolute;
        top: 0;
        left: 0;
        font-size: 5rem;
    }

    p {
        font-family: var(--font-pt-serif);
        font-weight: var(--font-weight-bold);
        font-style: italic;
        font-size: 2.8rem;
        line-height: 1.3;
        max-width: 58rem;
        color: var(--color-primary);

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
