/* Image Gallery */
@import '~photoswipe/dist/default-skin/default-skin.css';
@import '~photoswipe/dist/photoswipe.css';

.media__gallery {
    margin: 0;
    padding: 0;
    list-style: none;
    columns: 12rem 3;
    column-gap: .4rem;

    @media (--screen-large) {
        columns: 23rem 3;
    }

    li {
        margin-bottom: .4rem;
    }
}

.media__gallery__item {
    position: relative;
    display: block;
    overflow: hidden;

    img {
        width: 100%;
        display: block;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        opacity: 0;
        transition: all .3s ease-in-out;
    }

    &::after {
        background-color: var(--color-primary);
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &::before {
        top: 50%;
        left: 50%;
        z-index: 2;
        width: 1.8rem;
        height: 1.8rem;
        transform: translate(-50%, -50%);
        background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgMTggMTgiIHdpZHRoPSIxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtNjE2LjY2OTk2NCAyMzMwLjIzNDA1IDQuMDQzNSA0LjA0MzVjLjM5MDUyNC4zOTA1Mi4zOTA1MjQgMS4wMjM2OSAwIDEuNDE0MjFsLS4wMjE3MDUuMDIxN2MtLjM5MDUyNC4zOTA1My0xLjAyMzY4OS4zOTA1My0xLjQxNDIxMyAwbC00LjA0MzUtNC4wNDM1Yy0xLjI4MTE2NC45Nzg4My0yLjg4MjE1MyAxLjU2MDI1LTQuNjE4OTQgMS41NjAyNS00LjIwNTcwNyAwLTcuNjE1MTA2LTMuNDA5NC03LjYxNTEwNi03LjYxNTEgMC00LjIwNTcxIDMuNDA5Mzk5LTcuNjE1MTEgNy42MTUxMDYtNy42MTUxMSA0LjIwNTcwNiAwIDcuNjE1MTA1IDMuNDA5NCA3LjYxNTEwNSA3LjYxNTExIDAgMS43MzY3OC0uNTgxNDI0IDMuMzM3NzctMS41NjAyNDcgNC42MTg5NHptLTYuMDU0ODU4Ljk2NTQ3YzMuMDg0MTg1IDAgNS41ODQ0MTEtMi41MDAyMyA1LjU4NDQxMS01LjU4NDQxIDAtMy4wODQxOS0yLjUwMDIyNi01LjU4NDQyLTUuNTg0NDExLTUuNTg0NDJzLTUuNTg0NDExIDIuNTAwMjMtNS41ODQ0MTEgNS41ODQ0MmMwIDMuMDg0MTggMi41MDAyMjYgNS41ODQ0MSA1LjU4NDQxMSA1LjU4NDQxeiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjAzIC0yMzE4KSIvPjwvc3ZnPg==');
    }

    &:hover,
    &:focus {
        &::after {
            opacity: .4;
        }
        &::before {
            opacity: 1;
        }
    }
}

.pswp {
    z-index: 9999;
}

.pswp__caption, .pswp__caption__center {
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;
}
