.form__fieldset {
    display: block;
    border: 0;
    margin: 0 0 2rem;
    padding: 0;
    position: relative;
    clear: both;

    &.-cols {
        .form__option {
            @media (--screen-large) {
                width: 50%;
                float: left;
            }
        }
    }

    &.-group {
        border-bottom: .1rem solid var(--color-tertiary);
        padding: 2rem 0 4rem;
    }

    &.-hidden {
        display: none;
    }

    &.-extra {
        margin-bottom: 4rem;
    }
}
