.form__label, .form__legend {
    display: block;
    cursor: pointer;

    color: var(--color-gray);
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.7rem;
    line-height: 1.5;
    padding: 0 0 .5rem;

    &.-list {
        font-weight: var(--font-weight-bold);
        margin-top: 3.5rem;
    }

    small {
        font-size: 1.5rem;
    }
}
