@import '~@inventis/cookieconsent/src/cookie-consent.scss';

:root {
    --cookie-consent-bottom: 50%;
    --cookie-consent-max-width: 53.2rem;
    --cookie-consent-transform: translate3d(0, calc(50% + .5px), 0); // Fix blurry rendering in Chrome
    --cookie-consent-padding: 2rem;
}

.cookie-consent {
    border: 0;
    height: auto; // Fix Safari height issue
    height: fit-content;
    max-height: calc(100% - 7.3rem);
    transform: none !important;
    top: 7.3rem !important;
    bottom: auto !important;

    @media (--screen-medium-large) {
        padding: 3.5rem;
        top: 12.8rem !important;
        max-height: calc(100% - 12.8rem);
    }
}

.cookie-consent__header {
    text-align: left;
}

.cookie-consent__title {
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    font-size: 2.2rem;
    color: var(--color-black);
    line-height: 1.5;
    margin-top: 0;
}

.cookie-consent__inner {
    color: var(--color-gray);
    font-family: var(--font-pt-serif);
    font-size: 1.7rem;
    line-height: 1.5;

    p:not([class]) {
        margin: 1.4rem 0 3rem;
        font-size: 1.6rem;
    }

    a:not([class]) {
        color: var(--color-primary);
        transition: color .3s ease-in-out;

        &:hover, &:focus, &:active {
            color: var(--color-primary-20);
            text-decoration: none;
        }
    }
}

.cookie-consent__buttons {
    text-align: left;

    .button {
        margin: 0 1.2rem 1.2rem 0;
        padding: 1.6rem 2rem !important;
        font-size: 1.7rem;
        line-height: 1.5;

        &:after {
            display: none;
        }
    }
}

template.-has-iframe {
    font-size: 1.6rem;
    line-height: 1.5;
    padding-bottom: 56.25%;
    height: 0;
    min-height: 0;
}

.cookie-consent__options-toggle {
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    font-size: 1.7rem;
    color: var(--color-primary);
    border: 0;
    background: none;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    cursor: pointer;
    outline: none;

    svg {
        fill: var(--color-primary);
        stroke: var(--color-primary);
        vertical-align: middle;
        margin-right: .7rem
    }
}

.cookie-consent__options {
    background: var(--color-quaternary);
    padding: 2rem;
    margin-bottom: 2rem;
    position: relative;

    &::after {
        bottom: 100%;
        left: 4rem;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: var(--color-quaternary);
        border-width: 1.1rem;
    }

    .form__label {
        color: var(--color-black) !important;
        font-size: 1.5rem !important;
        font-weight: var(--font-weight-bold) !important;
    }

    .form__option {
        margin: 2rem 0 0 0;

        &:first-of-type {
            margin-top: 0;
        }
    }
}

.cookie-consent__option-description {
    color: var(--color-gray);
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.5rem;
    line-height: 1.3;
    display: block;
}
