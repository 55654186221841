.filter-selection {
    padding: 0;
    display: flex;
    align-items: center;
}

.filter-selection__title {
    font-family: var(--font-poppins);
    font-size: 1.4rem;
    text-transform: uppercase;
}

.filter-selection__list {
    margin: 0 1rem 0 0;
    padding: 0;
    list-style: none;
    display: inline-block;
}

.filter-selection__item {
    display: inline-block;
    margin-right: .4rem;

    &:last-child {
        margin-right: 0;
    }
}

.filter-selection__item__button {
    outline: none;
    border: 0;
    border-radius: .4rem;
    background: var(--color-secondary);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.4rem;
    color: var(--color-white);
    padding: .9rem;
    cursor: pointer;
    transition: all .3s;
    margin: 0 1rem 1rem 0;

    &::after {
        content: '\e907';
        display: inline-block;
        font-size: 1rem;
        font-family: 'icons';
        margin-left: .7rem;
    }

    &:hover, &:focus {
        background-color: var(--color-secondary-20);
    }

    &:last-child {
        margin-right: 0;
    }
}

.filter-selection__clear {
    color: var(--color-primary);
    position: relative;
    display: inline-block;
    line-height: 1;
    font-family: var(--font-pt-serif);
    font-size: 1.5rem;
    padding: .9rem;
    text-decoration: underline;
    margin-bottom: 1rem;

    &::before {
        content: '\e908';
        font-family: 'icons';
        display: inline-block;
        margin-right: .7rem;
    }

    &:hover, &:focus {
        text-decoration: none;
    }
}
