.filter-form {

    &.-is-loading {
        position: relative;
        z-index: 0;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--color-tertiary);
            background-image: linear-gradient(
                    -45deg,
                    var(--color-tertiary),
                    var(--color-tertiary) 25%,
                    var(--color-quaternary) 25%,
                    var(--color-quaternary) 50%,
                    var(--color-tertiary) 50%,
                    var(--color-tertiary) 75%,
                    var(--color-quaternary) 75%
            );
            background-size: 100px 100px;
            z-index: 3;
            animation: move .5s infinite linear;
            opacity: .5;
        }
    }
}

.filter-results-category__title {
    text-align: left;
    font-size: 2rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    color: var(--color-primary);
    line-height: 1.2;

    position: relative;

    margin: 1rem 0 2rem;

    @media (--screen-medium-large) {
        font-size: 2.8rem;
    }

    &::before {
        content: '';
        display: block;

        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: .2rem;

        background-color: var(--color-tertiary);
    }

    span {
        padding: 0 1.5rem 0 0;

        position: relative;
        z-index: 1;

        background-color: var(--color-white);
    }
}

@keyframes move {
    0%   { background-position: 0 0; }
    100% { background-position: 49px 49px; }
}
