.button {
    box-sizing: border-box;
    display: inline-block;
    padding: 1.7rem 4.2rem 1.7rem 2.2rem;
    background-color: var(--color-primary);
    border: .2rem solid var(--color-primary);
    color: var(--color-white);
    text-decoration: none;
    font-size: 2rem;
    font-weight: var(--font-weight-bold);
    font-family: var(--font-chivo);
    position: relative;
    margin: .8rem 0;
    transition: all .3s ease-in-out;
    cursor: pointer;

    & + .button {
        margin-left: 1.6rem;

        .page-footer__support & {
            @media (--screen-mini) {
                margin-left: .6rem;
            }
        }
    }

    &::after {
        content: '\e900';
        font-family: 'icons';
        display: block;
        color: var(--color-white);
        font-size: 1rem;
        position: absolute;
        top: calc(50% - .4rem);
        right: 2rem;
    }

    &:hover, &:focus, &:active {
        background-color: var(--color-primary-20);
        border-color: var(--color-primary-20);
    }

    &.-secondary {
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);

        &:hover, &:focus, &:active {
            background-color: var(--color-secondary-20);
            border-color: var(--color-secondary-20);
        }
    }

    &.-ghost {
        background-color: transparent;
        padding: 1.6rem 3.6rem 1.6rem 2rem;
        color: var(--color-primary);
        font-weight: var(--font-weight-normal);
        font-size: 1.7rem;
        border-color: var(--color-quaternary);

        &:hover, &:focus, &:active {
            background-color: var(--color-quaternary);
        }

        &::after {
            color: var(--color-primary);
        }

        .page-footer__support & {
            @media only screen and (max-width: 374px) {
                padding: 1.25rem;

                &::after {
                    display: none;
                }
            }
        }
    }

    &.-full {
        display: block;
        margin: 0;
        text-align: center;

        &::after {
            display: inline-block;
            position: relative;
            top: auto;
            right: auto;
            margin-left: 1rem;
        }
    }

    &.-upper {
        text-transform: uppercase;

        &::after {
            font-size: 1.2rem;
            top: calc(50% - .5rem);
            position: absolute;
        }
    }

    &.-extra-margin {
        margin-bottom: 2rem;
    }

    &.-disabled {
        background-color: var(--color-gray);
        border-color: var(--color-gray);
        padding: 1.7rem 2.2rem;

        &::after {
            display: none;
        }
    }

    &.-list {
        margin-top: 3rem;
        margin-right: 3rem;
    }

    &.-hidden {
        display: none;
    }
}
