.date-picker-month {
    width: 100%;
    padding: 0 1.5rem 3.5rem;

    border-bottom: .1rem solid var(--color-tertiary);
}

.date-picker-month thead {
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.1rem;
    color: var(--color-black);
    opacity: .5;
    letter-spacing: .15rem;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;

    td {
        padding-bottom: 1rem;
    }
}
