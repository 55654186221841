.page-header__top {
    text-align: right;
    padding: 1.5rem 3rem;
    display: none;

    @media (--screen-medium-large) {
        display: block;
    }

    &::after {
        content: '';
        display: block;
        background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ1MzkiIHZpZXdCb3g9IjAgMCAyOTc0IDQ1MzkiIHdpZHRoPSIyOTc0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDAgMTI5Ni41NSA0NTM5aDE2NzcuNDVsLTEyOTYuNjEtNDUzOXoiIGZpbGw9IiM1NmJkYmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: top left;

        position: absolute;
        top: 0;
        left: calc(100% - 21.8rem);
        min-height: 100vh;
        height: 100%;
        width: 100vw;
        z-index: -1;

        .-position-fixed & {
            position: fixed;
        }

        @media (--screen-end) {
            left: 50%;
            transform: translateX(51.2rem);
        }

        .-theme-blue & {
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ1MzkiIHZpZXdCb3g9IjAgMCAyOTc0IDQ1MzkiIHdpZHRoPSIyOTc0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDAgMTI5Ni41NSA0NTM5aDE2NzcuNDVsLTEyOTYuNjEtNDUzOXoiIGZpbGw9IiMwMEEzREEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
        }

        .-theme-pink & {
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ1MzkiIHZpZXdCb3g9IjAgMCAyOTc0IDQ1MzkiIHdpZHRoPSIyOTc0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDAgMTI5Ni41NSA0NTM5aDE2NzcuNDVsLTEyOTYuNjEtNDUzOXoiIGZpbGw9IiNFMzgxOUUiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
        }

        .-theme-orange & {
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ1MzkiIHZpZXdCb3g9IjAgMCAyOTc0IDQ1MzkiIHdpZHRoPSIyOTc0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDAgMTI5Ni41NSA0NTM5aDE2NzcuNDVsLTEyOTYuNjEtNDUzOXoiIGZpbGw9IiNGRDkxNTciIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
        }

        .-theme-green & {
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ1MzkiIHZpZXdCb3g9IjAgMCAyOTc0IDQ1MzkiIHdpZHRoPSIyOTc0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0wIDAgMTI5Ni41NSA0NTM5aDE2NzcuNDVsLTEyOTYuNjEtNDUzOXoiIGZpbGw9IiM5ODlBMDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
        }
    }

    .-navigation-active & {
        @media (--screen-mini-max) {
            display: none;
        }
    }
}

.page-header__phone {
    color: var(--color-white);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;
    text-decoration: none;
    padding-left: 2rem;
    transition: color .3s ease-in-out;

    &:hover, &:focus, &:active {
        color: var(--color-primary);
    }

    svg {
        fill: var(--color-primary);
    }
}
