body {
    overflow-x: hidden;
    position: relative;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    width: 100vw;
    min-height: 100vh;
    min-width: 32rem;
    margin: 0 auto;

    word-wrap: break-word; // Don't let long words break the layout on small area's

    &.-navigation-active {
        @media (--screen-mini-max) {
            position: fixed;
            overflow: hidden;
            height: 100vh;

            /* Prevent iOS safari from still showing main content */
            @supports (-webkit-overflow-scrolling: touch) {
                main {
                    opacity: 0;
                }
            }
        }
    }

    &.-search-active {
        position: fixed;
        overflow: hidden;
        height: 100vh;

        /* Prevent iOS safari from still showing main content */
        @supports (-webkit-overflow-scrolling: touch) {
            main {
                opacity: 0;
            }
        }
    }
}
