.page-header__cart {
    display: inline-block;
    margin-left: auto;
    margin-right: 1rem;
    position: relative;

    @media (--screen-medium-large) {
        margin-left: 1.5rem;
        margin-right: 0;
    }

    svg {
        fill: var(--color-black);
        stroke: var(--color-black);

        vertical-align: middle;

        transition: all .3s ease-in-out;
    }

    &:hover, &:focus, &:active {
        color: var(--color-primary);

        svg {
            fill: var(--color-primary);
            stroke: var(--color-primary);
        }
    }

    .-navigation-active & {
        @media (--screen-mini-max) {
            display: none;
        }
    }
}

.page-header__cart__total {
    background-color: var(--color-primary);
    color: var(--color-white);
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: -.8rem;
    right: -.8rem;
    border-radius: 50%;
    text-align: center;
    line-height: 1.6rem;
    font-size: 1rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);

    &.-hidden {
        display: none;
    }
}
