.navigation {
    display: none;

    @media (--screen-medium-large) {
        display: inline-block;
        margin-left: auto;
    }

    @media (--screen-end) {
        margin-left: 0;
    }

    .-navigation-active & {
        @media (--screen-mini-max) {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 10rem 2rem 0;
            box-sizing: border-box;

            @media (--screen-medium-large) {
                height: 100%;
                padding: 20rem 8rem 0;
                width: 50%;
                margin: 0;
                display: block;
                float: left;
            }
        }
    }
}

.navigation__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navigation__item {
    display: inline-block;
    padding: 0 1.5rem;

    .-navigation-active & {
        @media (--screen-mini-max) {
            padding: 1rem 0;
            display: block;

            @media (--screen-medium-large) {
                padding: 1.5rem 0;
            }
        }
    }

    a {
        font-family: var(--font-chivo);
        font-weight: var(--font-weight-bold);
        font-size: 2.2rem;
        color: var(--color-dark-gray);
        text-decoration: none;
        display: inline-block;
        position: relative;

        .ampersand {
            font-family: var(--font-ampersand);
            font-weight: var(--font-weight-bold);
            font-style: normal;
        }

        &::after {
            content: '';
            width: 100%;
            height: .3rem;
            background-color: var(--color-secondary);
            position: absolute;
            left: 0;
            bottom: -.5rem;
            z-index: 0;
            display: inline-block;
            transition: all .3s ease-in-out;
            transform: scaleX(0);
        }

        .-navigation-active & {
            @media (--screen-mini-max) {
                color: var(--color-white);
                font-size: 3.2rem;

                @media (--screen-medium-large) {
                    font-size: 4.4rem;
                }
            }
        }

        &:hover, &:focus, &:active {
            color: var(--color-dark-gray);

            .-navigation-active & {
                @media (--screen-mini-max) {
                    color: var(--color-white);
                }
            }

            &::after {
                transform: scaleX(1);
            }
        }
    }

    &.-active a::after {
        transform: scaleX(1);
    }

    &.-mobile {
        @media (--screen-medium-large) {
            display: none;

            .-navigation-active & {
                @media (--screen-mini-max) {
                    display: block;
                }
            }
        }
    }
}
