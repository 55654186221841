.events-item {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    flex-basis: 100%;
    overflow: hidden;
    box-sizing: border-box;
    margin-bottom: .4rem;
    font-size: 0;

    @media (--screen-medium-large) {
        flex-basis: calc(50% - .2rem);

        &.-big {
            flex-basis: 100%;
        }

        &:nth-child(2n) {
            margin-right: auto;
        }
    }

    &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all .3s ease-in-out;
        z-index: 0;
    }

    &::after {
        background-image: linear-gradient(180deg, color-mod(var(--color-black) a(30%)) 0%, color-mod(var(--color-black) a(10%)) 21%, color-mod(var(--color-black) a(30%)) 100%);
    }

    &::before {
        background-color: var(--color-primary);
        opacity: 0;
        z-index: 1;
    }

    &:hover, &:focus, &:active {
        &::before {
            opacity: .4;
        }

        .events-item__img {
            transform: scale(1.05);
        }
    }
}

.events-item__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
    transform-origin: center;
}

.events-item__info {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2rem;
    z-index: 1;
}

.events-item__date {
    background-color: var(--color-secondary);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.5rem;
    color: var(--color-white);
    display: inline-block;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    margin-left: -2rem;
    padding: 1rem;
    position: relative;
    transition: all .3s ease-in-out;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -.6rem;
        display: inline-block;
        width: 1.2rem;
        height: 100%;
        transform: skew(15deg);
        background-color: var(--color-secondary);
        z-index: 0;
    }

    .events-detail__img & {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0;
        z-index: 1;

        padding-left: 1.5rem;

        @media (--screen-filter) {
            display: none;
        }
    }
}

.events-item__title {
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-black);
    font-size: 2rem;
    color: var(--color-white);
    display: block;
    margin-bottom: 1rem;
    transition: all .3s ease-in-out;

    .ampersand {
        font-family: var(--font-ampersand);
        font-weight: var(--font-weight-black);
        font-style: normal;
    }

    .events-item.-big & {
        @media (--screen-medium-large) {
            font-size: 4.4rem;
        }
    }
}

.events-item__subtitle {
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;
    color: var(--color-white);
    display: block;
    font-style: normal;
}

.events-item__status {
    color: var(--color-white);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 1.5rem;
    padding: 0 4.5rem 0 1.5rem;

    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    height: 4rem;
    line-height: 4rem;
    transition: all .3s ease-in-out;

    em {
        display: block;
        color: var(--color-white);
        font-size: .8rem;
        position: absolute;
        top: 0;
        right: -1rem;
        width: 4rem;
        height: 4rem;
        z-index: 2;
        padding-right: .5rem;
        text-align: center;
        vertical-align: middle;
        line-height: 4rem;
        transition: all .3s ease-in-out;
    }

    &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -1rem;
        background-color: var(--color-primary);
        width: 4.5rem;
        height: 4rem;
        transform: skew(15deg);
        transition: all .3s ease-in-out;
    }

    &::before {
        background-color: var(--color-white);
        color: var(--color-primary);
        z-index: 1;
        transform: skew(15deg) scaleX(0) ;
        transform-origin: left center;
    }

    .events-item:hover & {
        padding-right: 5rem;

        &::before {
            transform: skew(15deg) scaleX(1);
        }

        em {
            color: var(--color-primary);
        }
    }

    &.-gray {
        padding: 0 1.5rem 0 2.5rem !important;

        &:after, em {
            display: none;
        }

        &:before {
            background: var(--color-black);
            opacity: .7;
            transform: skew(15deg) !important;
            width: 100%;
            z-index: -1;
        }
    }
}

.events-list .events-item {
    display: block;
    border-top: .1rem solid var(--color-tertiary);
    padding: 2.5rem;
    text-decoration: none;
    margin: 0;

    &::before, &::after {
        display: none;
    }

    &:first-child {
        border-top: 0;
    }

    .events-item__date {
        color: var(--color-primary);
        background-color: transparent;
        margin: 0 0 1rem 0;
        padding: 0;
        font-weight: var(--font-weight-bold);

        &::after {
            display: none;
        }
    }

    .events-item__title {
        color: var(--color-black);
        font-weight: var(--font-weight-normal);
        font-size: 2rem;
        line-height: 1.3;
        margin-bottom: .4rem;

        .ampersand {
            font-weight: var(--font-weight-medium);
        }
    }

    .events-item__subtitle {
        color: var(--color-gray);
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
    }

    .events-item__status {
        position: relative;
        padding: 0;
        color: var(--color-primary);

        &::before, &::after {
            display: none;
        }

        em {
            color: var(--color-primary);
            position: relative;
            width: auto;
            height: auto;
            top: auto;
            right: auto;
            display: inline-block;
            padding: 0 0 0 .5rem;
            line-height: 1;
        }

        &.-gray {
            padding: 0 !important;
            color: var(--color-dark-gray);

            em {
                color: var(--color-dark-gray);
            }
        }
    }

    &:hover, &:focus, &:active {

        .events-item__title {
            color: var(--color-primary);
        }

        .events-item__date, .events-item__status {
            color: var(--color-primary-20);
        }
    }
}
