.filter-picker {
    margin: 0;
    padding: 0;
    border: 0;

    position: relative;
    align-self: stretch;
    display: flex;
    align-items: stretch;

    flex-basis: calc(50% - .1rem);

    border-top: .2rem solid var(--color-white);
    border-left: .2rem solid var(--color-white);

    @media (--screen-max) {
        border-top: 0;
        flex-basis: auto;
        border-top: 0;
    }

    &.-open {
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: .3rem;
            background-color: var(--color-quaternary);
            position: absolute;
            right: 0;
            bottom: -.3rem;
        }
    }
}

.filter-picker__label {
    padding: 2rem 4rem 2rem 1.5rem;
    width: 100%;
    box-sizing: border-box;
    height: 100%;

    background-color: transparent;
    border: 0;
    outline: none;
    cursor: pointer;

    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);
    font-size: 0;
    color: var(--color-white);
    line-height: 1;
    text-align: left;

    position: relative;
    transition: background-color .3s ease-in-out, color .3s ease-in-out;

    span {
        font-size: 1.6rem;
        text-transform: capitalize;
    }

    @media (--screen-medium-large) {
        font-size: 1.6rem;
    }

    .filter-picker.-open & {
        background-color: var(--color-quaternary);
        color: var(--color-primary)
    }

    &::before {
        content: '\e903';
        font-family: 'icons';
        display: inline-block;
        font-size: 2rem;

        color: var(--color-secondary);
        margin-right: 1rem;

        position: relative;
        top: .2rem;
    }

    &::after {
        content: '\e900';
        font-family: 'icons';
        display: inline-block;
        font-size: 1rem;

        color: var(--color-white);

        position: absolute;
        top: calc(50% - .2rem);
        right: 1.5rem;
        transform: rotate(90deg);
        transition: all .3s ease-in-out;

        .filter-picker.-open & {
            color: var(--color-primary);
            transform: rotate(-90deg);
        }
    }

    &:hover, &:focus {
        background-color: var(--color-primary-20);
    }
}

.filter-picker__options {
    width: 100vw;
    max-width: 29rem;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 1.5rem;

    opacity: 0;
    max-height: 0;

    position: absolute;
    right: 0;
    top: calc(100% + .2rem);

    z-index: -1;

    background-color: var(--color-quaternary);

    transition: opacity .3s ease-in-out;

    @media (--screen-medium-large) {
        max-width: 37.6rem;
    }

    @media (--screen-mini-filter) {
        min-width: 29rem;
        max-width: 100%;
    }

    .filter-picker.-open & {
        opacity: 1;
        z-index: 5;
        padding: 1.5rem;
        max-height: 100vh;
    }

    .filterable {
        display: block !important;
        margin-bottom: 1.5rem !important;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
