@import 'config.scss';

:root {
    --color-primary       : #005949;
    --color-secondary     : #56BDBF;
    --color-tertiary      : #CBE0E0;
    --color-quaternary    : #E4F0F0;

    --color-primary-20    : color-mod(#005949, tint(20%));
    --color-primary-60    : color-mod(#005949, tint(60%));

    --color-secondary-20  : color-mod(#56BDBF, tint(20%));
}

@import 'components/**/[!_]*.scss';
@import 'components/Front/_helpers.scss'; // helpers last to overwrite previous styling
