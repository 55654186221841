.container {
    display: block;
    clear: both;
    width: 100%;
    max-width: var(--max-width);
    box-sizing: border-box;

    margin: 0 auto;
    padding: 0 1.5rem;

    @media (--screen-max) {
        padding: 0 3rem;
    }

    &.-padded-bottom {
        padding-bottom: 6rem;

        &.-extra {
            padding-bottom: 12rem;
        }

        &.-less {
            padding-bottom: 4rem;
        }

        @media (--screen-medium-large) {
            padding-bottom: 12rem;

            &.-extra {
                padding-bottom: 20rem;
            }

            &.-less {
                padding-bottom: 9rem;
            }
        }
    }

    &.-small {
        @media (--screen-max) {
            max-width: 96rem;
        }
    }

    &::after {
        display: block;
        content: '';
        clear: both;
    }
}
