.article-cta {
    background-color: var(--color-primary);
    max-width: 72rem;
    padding: 5rem 2.5rem 7rem;
    box-sizing: border-box;
    margin: 0 1.5rem;
    position: relative;
    top: -9rem;
    text-align: center;
    line-height: 1.5;
    font-size: 1.8rem;
    color: var(--color-white);
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);

    @media (--screen-medium-large) {
        padding: 6.5rem 6.5rem 7.5rem;
        margin: 0 auto;
    }

    p {
        margin-bottom: 2.5rem;
    }
}

.article-cta__title {
    line-height: 1.15;
    font-size: 3rem;
    color: var(--color-white);
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-black);
    display: block;
    margin-bottom: 2rem;

    @media (--screen-medium-large) {
        font-size: 4.4rem;
    }

    .ampersand {
        font-family: var(--font-ampersand);
        font-weight: var(--font-weight-black);
        font-style: normal;
    }
}

.article-cta__slashes {
    position: absolute;
    bottom: -2rem;
    left: -3rem;
    fill: var(--color-secondary);

    @media (--screen-medium-large) {
        left: -4rem;
        width: 15rem;
        height: 12rem;
    }
}
