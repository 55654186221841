.page-footer {
    flex-shrink: 0;
    padding-top: 3rem;
    border-top: .1rem solid var(--color-tertiary);

    @media (--screen-medium-large) {
        padding-top: 9rem;
    }
}

.page-footer__logo {
    display: inline-block;
    margin-bottom: 2rem;
}

.page-footer__info {
    font-size: 1.7rem;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    line-height: 1.5;
    margin-bottom: 4rem;
}

.page-footer__social {
    margin: 0;
    padding: 2rem 0;
    display: flex;
    list-style: none;

    svg {
        fill: var(--color-primary);
    }

    > li {
        margin-left: 3rem;

        &:first-child {
            margin-left: 0;
        }
    }

    a:hover, a:focus, a:active {
        svg {
            fill: var(--color-primary-20);
        }
    }
}

.page-footer__title {
    font-family: var(--font-chivo);
    font-size: 2.2rem;
    font-weight: var(--font-weight-normal);
    display: inline-block;
    padding-bottom: 1rem;
}

.page-footer__link {
    color: var(--color-primary);
    text-decoration: none;
    font-size: 1.7rem;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    line-height: 1.5;

    svg {
        fill: var(--color-primary);
        stroke: var(--color-primary);
        margin-right: .7rem;
        vertical-align: middle;
    }

    &:hover, &:focus, &:active {
        text-decoration: underline;
    }
}

.page-footer__top {
    @media (--screen-medium-large) {
        display: flex;
    }
}

.page-footer__slashes {
    fill: var(--color-primary);
    position: absolute;
    bottom: 0;
    right: -2rem;
    z-index: -1;
    transform: scaleX(-1);
    display: none;

    @media (--screen-max) {
        display: block;
    }

    .-search-active & {
        display: none;
    }

    .-position-fixed & {
        display: none;
    }
}
