.tags__list {
    margin: 0 0 3rem;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    .card-item & {
        margin: 0 0 2rem;
    }
}

.tag__item {
    display: block;
    margin: .7rem .7rem 0 0;
    padding: .5rem;
    color: var(--color-primary-60);
    border: .1rem solid var(--color-tertiary);
    border-radius: .4rem;
    text-decoration: none;
    transition: color .3s ease-in-out;
    line-height: 1.25;
    font-size: 1.4rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-normal);

    .ampersand {
        font-family: var(--font-ampersand);
        font-weight: var(--font-weight-normal);
        font-style: normal;
    }

    &:hover, &:focus, &:active {
        color: var(--color-primary);
    }

    &.-small {
        padding: .25rem;

        &:hover, &:focus, &:active {
            color: var(--color-primary-60);
        }
    }
}
