.wrapper {
    display: block;
    clear: both;
    position: relative;

    &.-primary {
        background-color: var(--color-primary);
    }

    &.-secondary {
        background-color: var(--color-secondary);
    }

    &.-tertiary {
        background-color: var(--color-tertiary);
    }

    &.-quaternary {
        background-color: var(--color-quaternary);
    }

    &.-cta {
        margin-bottom: 6rem;

        @media (--screen-max) {
            margin-bottom: 18rem;
        }
    }
}
