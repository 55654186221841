.highlight {
    &.-home {
        position: relative;
        top: -4rem;
        margin-bottom: -4rem;

        margin-left: auto;
        margin-right: auto;

        @media (--screen-medium-large) {
            top: -15rem;
            margin-bottom: -15rem;
        }
    }
}

.highlight-grid {
    display: flex;
    margin: 0;
    justify-content: center;
    flex-wrap: wrap;

    @media (--screen-medium-large) {
        margin: 0 -1rem;
    }
}
