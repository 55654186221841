.page-header {
    display: block;
    margin-bottom: 2.4rem;

    @media (--screen-medium-large) {
        margin-bottom: 4.4rem;
    }

    .-navigation-active & {
        @media (--screen-mini-max) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: 0;
            z-index: 99;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--color-primary);
        display: none;

        .-navigation-active & {
            @media (--screen-mini-max) {
                display: block;
            }
        }
    }
}

.page-header__slashes {
    position: absolute;
    top: 100%;
    right: 2.5rem;
    z-index: 1;
    fill: var(--color-secondary);
    transform: translateY(-100%);
    display: none;

    .-navigation-active & {
        @media (--screen-mini-max) {
            display: block;
        }
    }
}
