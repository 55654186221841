@font-face {
    font-family: 'icons';
    src:  url('icons/icons.eot?pr0eum');
    src:  url('icons/icons.eot?pr0eum#iefix') format('embedded-opentype'),
    url('icons/icons.ttf?pr0eum') format('truetype'),
    url('icons/icons.woff?pr0eum') format('woff'),
    url('icons/icons.svg?pr0eum#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
    content: "\e900";
    color: currentColor;
}
.icon-arrow-left:before {
    content: "\e901";
    color: currentColor;
}
.icon-date:before {
    content: "\e902";
    color: currentColor;
}
.icon-filter:before {
    content: "\e903";
    color: currentColor;
}
.icon-wheelchair:before {
    content: "\e904";
    color: currentColor;
}
.icon-ear:before {
    content: "\e905";
    color: currentColor;
}
.icon-check:before {
    content: "\e906";
    color: currentColor;
}
.icon-close:before {
    content: "\e907";
    color: currentColor;
}
.icon-refresh:before {
    content: "\e908";
    color: currentColor;
}
.icon-ticket:before {
    content: "\e909";
    color: currentColor;
}
.icon-people:before {
    content: "\e90a";
    color: currentColor;
}
.icon-location:before {
    content: "\e90b";
    color: currentColor;
}
.icon-facebook:before {
    content: "\e90c";
    color: currentColor;
}
.icon-quote:before {
    content: "\e90d";
    color: currentColor;
}
