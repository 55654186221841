.filter {
    width: 100%;
    max-width: 100%;
}

.filter__submit {
    display: none;
}

.filter__filters {
    display: flex;
    flex-wrap: wrap;
    clear: both;
    width: 100%;
    background: var(--color-primary);
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    z-index: 5;

    .filterable {
        display: block;
        border: 0;
        padding: 0;
        margin: 0;

        &.filter__guest, &.filter__cirkaboem, &.filter__schoolco {
            flex-basis: 100%;
            padding: 0;
            margin: 1.5rem;

            @media (--screen-large) {
                flex-basis: auto;
                padding: 0 0 0 2rem;
                margin: 0;
            }

            .filter__title {
                display: none;
            }
            .filter__option {
                display: block;
                padding: 1.2rem 1.4rem;
                font-family: var(--font-chivo);
                font-size: 1.8rem;
                font-weight: var(--font-weight-bold);
                color: var(--color-white);
                background-color: transparent;
                border-radius: .4rem;
                transition: background-color .3s ease-in-out;
                cursor: pointer;

                &::after {
                    content: '\e906';
                    font-family: 'icons';
                    font-size: 1rem;

                    color: var(--color-white);
                    margin-left: 1rem;

                    position: relative;
                    top: .4rem;
                    float: right;
                    opacity: 0;
                    transition: all .3s ease-in-out;
                }
            }

            input[type=checkbox] {
                display: none;

                &:checked + label {
                    background-color: var(--color-secondary);
                    border-radius: .4rem;

                    &::after {
                        opacity: 1;
                    }
                }
            }
        }

        &.filter__accessibility {
            .filter__flex {
                display: flex;
                align-items: center;
            }

            .filter__title {
                color: var(--color-primary);
                font-size: 1.6rem;
                font-family: var(--font-chivo);
                font-weight: var(--font-weight-normal);
                margin-right: .7rem;
            }

            .filter__option {
                display: inline-block;
                width: 5rem;
                height: 5rem;
                font-size: 0;
                position: relative;
                border: .1rem solid var(--color-tertiary);
                cursor: pointer;

                &::after {
                    font-family: 'icons';
                    font-size: 2rem;

                    color: var(--color-primary);
                    width: 5rem;
                    height: 5rem;
                    line-height: 5rem;
                    text-align: center;

                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: all .3s ease-in-out;
                    overflow: hidden;
                }

                &.accessibility__wheelchair::after {
                    content: '\e904';
                }

                &.accessibility__hearing_impaired::after {
                    content: '\e905';
                }
            }

            .filter__content {
                display: flex;

                div {
                    margin-left: .7rem;
                }
            }

            input[type=checkbox] {
                display: none;

                &:checked + label {
                    background-color: var(--color-tertiary);
                }
            }
        }
    }

    .date-picker {
        margin-left: auto;
    }
}

.filter__result {
    display: block;
    clear: both;
    padding: 1rem 0;
    margin: 0;
}

.filter__filters__links {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
    width: 100%;

    border-bottom: .2rem solid var(--color-white);

    @media (--screen-large) {
        width: auto;
        padding: 0 1.5rem 0 0;
        border-bottom: 0;
        border-right: .1rem solid color-mod(var(--color-white) a(20%));
    }

    li {
        display: inline-block;
    }

    &.-children {
        padding: 0 0 0 1rem;
        width: calc(100% - 3rem);
        box-sizing: border-box;
        border: 0;
        margin: 0 0 2rem;

        @media (--screen-filter) {
            padding: 0 0 0 2rem;
            margin-bottom: 4rem;
        }

        @media (--screen-max) {
            width: 140rem;
        }
    }

    &.-school {
        border-right: 0 !important;
    }
}

.filter__filters__item {
    display: block;
    color: var(--color-white);
    font-weight: var(--font-weight-bold);
    font-size: 1.6rem;
    font-family: var(--font-chivo);
    text-decoration: none;
    padding: 1.5rem;
    line-height: 1.25;
    box-sizing: border-box;
    position: relative;

    @media (--screen-medium-large) {
        padding: 2.5rem 2rem;
        font-size: 1.8rem;
    }

    span {
        display: inline-block;
        position: relative;

        &::after {
            content: '';
            display: block;
            background-color: var(--color-secondary);
            width: 100%;
            height: .3rem;
            position: absolute;
            bottom: -.5rem;
            left: 0;
            transform: scaleX(0);
            transition: transform .3s ease-in-out;
        }
    }

    &:hover, &:focus, &:active {
        span::after {
            transform: scaleX(1);
        }
    }

    &.-child {
        color: var(--color-primary);
        font-weight: var(--font-weight-normal);
        font-family: var(--font-pt-serif);
        font-size: 1.5rem;
        margin: 0 2rem 1rem 0;
        padding: 0;

        &.-active {
            font-weight: var(--font-weight-bold);
        }
    }

    &.-active {
        font-weight: var(--font-weight-bold);

        span::after {
            transform: scaleX(1);
        }
    }
}

.filter__no-results {
    font-size: 1.5rem;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    color: var(--color-black);
    text-align: center;
}

.filter__group {
    display: flex;
    justify-content: start;
    align-items: center;

    .filter__cirkaboem, .filter__schoolco {
        flex-basis: auto !important;
    }

    .filter__schoolco {
        display: flex;
        align-items: center;
    }

    @media (--screen-mini-medium) {
        width: 100%;
        flex-wrap: wrap;
    }
}
