.events {
    margin-bottom: 8rem;

    &.-padded-top {
        padding-top: 11rem;
    }

    &.-extra {
        margin-bottom: 18rem;
    }

    @media (--screen-max) {
        margin-bottom: 14.5rem;

        &.-extra {
            margin-bottom: 24rem;
        }
    }
}

/* autoprefixer grid: on */
.events-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (--screen-max) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "one one two" "one one three" "four five six";
        grid-gap: .4rem;

        :first-child {
            grid-area: one;
        }

        :nth-child(2) {
            grid-area: two;
        }

        :nth-child(3) {
            grid-area: three;
        }

        :nth-child(4) {
            grid-area: four;
        }

        :nth-child(5) {
            grid-area: five;
        }

        :nth-child(6) {
            grid-area: six;
        }
    }
}
/* autoprefixer grid: off */

.events__title {
    color: var(--color-black);
    line-height: 1.05;
    font-size: 2.6rem;
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-black);
    display: block;
    margin: 0 0 2rem;
    text-align: center;

    @media (--screen-max) {
        font-size: 4.4rem;
    }
}
