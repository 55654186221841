.highlight-item {
    display: block;
    text-decoration: none;
    width: 100%;
    margin: 1rem 0;
    background-color: var(--color-quaternary);
    transition: all .3s ease-in-out;

    @media (--screen-medium-large) {
        width: calc(50% - 2rem);
        margin: 1rem;
    }

    @media (--screen-filter) {
        width: calc(33% - 2rem);
        margin: 1rem;
    }

    @media (--screen-max) {
        width: calc(25% - 2rem);
    }

    &:hover, &:focus, &:active {
        .highlight-item__title {
            color: var(--color-primary);
        }

        .highlight-item__img::after {
            opacity: .4;
        }

        .highlight-item__img img {
            transform: scale(1.05);
        }

        .highlight-item__link {
            color: var(--color-primary-20);

            &::after {
                margin-left: 2rem;
                color: var(--color-primary-20);
            }
        }
    }
}

.highlight-item__img {
    display: block;
    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        transition: all .3s ease-in-out;
        transform-origin: center;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        background-color: var(--color-primary);
        z-index: 1;
        transition: all .3s ease-in-out;
    }
}

.highlight-item__content {
    padding: 2.5rem 2.5rem 4rem;
    color: var(--color-gray);
    font-size: 1.7rem;
    font-family: var(--font-pt-serif);
    font-weight: var(--font-weight-normal);
    line-height: 1.5;

    p {
        margin-top: 0;
    }
}

.highlight-item__title {
    font-family: var(--font-chivo);
    font-weight: var(--font-weight-bold);
    font-size: 2rem;
    color: var(--color-black);
    display: block;
    transition: all .3s ease-in-out;
    padding-bottom: 2rem;
    line-height: 1.1;

    .ampersand {
        font-family: var(--font-ampersand);
        font-weight: var(--font-weight-bold);
        font-style: normal;
    }
}

.highlight-item__link {
    position: relative;
    color: var(--color-primary);
    font-family: var(--font-chivo);
    font-size: 1.7rem;
    font-weight: var(--font-weight-bold);
    transition: all .3s ease-in-out;

    &::after {
        transition: all .3s ease-in-out;
        content: '\e900';
        font-family: 'icons';
        display: inline-block;
        color: var(--color-primary);
        font-size: 1rem;
        margin-left: 1.5rem;
        position: relative;
        top: -.1rem;
    }
}
